@use '@angular/material' as mat;
@use 'variables' as dt;

@import 'ress/ress.css';
@import 'material-theme';
@import 'material-theme-overrides';
@import 'forms';
@import 'quill-editor';

mat-form-field.dt-no-label {
  @include mat.form-field-density(-5);
}

mat-form-field.dt-slim {
  @include mat.form-field-density(-1);
}

mat-form-field.dt-number-input {
  &.dt-number-input-decimal {
    .mat-mdc-form-field-infix {
      width: min-content;

      input {
        width: 6ch;
      }
    }
  }

  &:not(.dt-no-label) {
    @include mat.form-field-density(-1);
  }

  input,
  label {
    text-align: center;
  }

  .mat-mdc-form-field-focus-overlay {
    background-color: unset;
  }
}

mat-icon {
  flex-shrink: 0;
}

mat-icon.material-symbols-outlined {
  font-variation-settings:
    'FILL' 1,
    'wght' 400,
    'GRAD' 0,
    'opsz' 48;
}

body.dt-webkit mat-icon.material-symbols-outlined {
  // Weight 400 does not work in Safari, so we use weight 300
  font-variation-settings:
    'FILL' 1,
    'wght' 300,
    'GRAD' 0,
    'opsz' 48;
}

html {
  scroll-behavior: smooth;

  hyphens: auto;
  word-wrap: break-word;

  // Enable font smoothing in Safari
  -webkit-font-smoothing: antialiased;
}

// Prevent certain things from being hyphenated
mat-error::before, // The icon from mat-error
mat-chip, button {
  white-space: nowrap;
}
