@use '@angular/material' as mat;
@use 'variables' as dt;

.dt-plain-list {
  padding-left: 0;
  margin-bottom: 1rem;
  margin-left: 20px;
}
ul.dt-plain-list li {
  list-style-type: disc;
  padding-left: 1rem;
}

ol.dt-plain-list li {
  padding-left: 1rem;
}

.mat-typography {
  .bold {
    font-weight: bold;
  }

  .italic {
    font-style: italic;
  }

  p {
    text-align: justify;
    hyphens: auto;
  }

  h4 {
    @include dt.typography-level(subtitle-2);
  }
}

a {
  color: dt.$orange;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

h1,
h2,
h3,
h4 {
  color: dt.$blue;
}

.dt-limit-width {
  max-width: 1300px;
  margin: 0 auto;
  width: 100%;
}

.dt-starter-limit-width {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.dt-blue {
  color: dt.$blue;
}

.dt-reserve-bold-width:not([data-text])::before {
  content: '.dt-reserve-bold-width needs a data-text attribute.';
  color: red;
  font-weight: bold;
}

/*
  * Reserve space for bold text to prevent layout shift upon highlight.
  * Adapted from https://codepen.io/hexagoncircle/pen/WNrYPLo.
  *
  * Usage:
  * <span class="dt-reserve-bold-width" data-text="Some text">Some text</span>
*/
.dt-reserve-bold-width::after {
  display: block;

  content: attr(data-text);
  font-weight: bold;

  height: 0;

  visibility: hidden;
  overflow: hidden;
  user-select: none;
  pointer-events: none;

  @media speech {
    display: none;
  }
}
