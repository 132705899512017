@use 'sass:meta';
@use 'sass:string';
@use '@angular/material' as mat;
@use 'variables' as dt;

// required to override specificity of default angular material typography
.mat-typography quill-editor {
  @include meta.load-css('node_modules/quill/dist/quill.core.css');
  @include meta.load-css('node_modules/quill/dist/quill.snow.css');
  @include meta.load-css('node_modules/quill/dist/quill.bubble.css');

  $quill-font-size: dt.font-size(body-2);

  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    // reset global styles (mat-typography and own)
    font: inherit;
    color: unset;
  }

  .ql-editor {
    flex: 1;
    flex-basis: 250px;

    overflow: visible;

    strong {
      font-weight: bold;
    }

    p,
    li {
      font-size: $quill-font-size;
    }

    img {
      width: initial;
    }

    video,
    iframe {
      display: inline;
      float: left;
      width: 500px;
      margin: 0 1em 1em 0;
    }

    iframe {
      height: 281px;
    }

    &.ql-blank::before {
      color: black;
      opacity: 0.4;
      font-style: normal;
      font-size: $quill-font-size;
    }
  }

  .ql-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
  }

  .ql-toolbar {
    text-align: left;

    background-color: #e2e4e5;
    border-bottom: 2px solid #b5b6b7;

    white-space: nowrap;
    overflow: auto;

    .ql-formats .ql-picker .ql-picker-options {
      position: fixed;
      min-width: unset;
      top: unset;
      z-index: 10;
    }

    @supports (anchor-scope: all) {
      .ql-formats {
        anchor-scope: all;

        .ql-picker {
          anchor-name: --picker;

          .ql-picker-options {
            top: anchor(bottom);
            left: anchor(left);

            position-visibility: anchors-visible;
            position-anchor: --picker;

            min-width: anchor-size(width);
          }
        }
      }
    }
  }

  .ql-tooltip[data-mode='image']::before {
    content: 'Enter Image URL:';
  }

  .ql-tooltip[data-mode='video']::before {
    content: 'Enter YouTube URL:' !important;
  }

  .ql-tooltip[data-mode='native-video']::before {
    content: 'Enter Video URL:';
  }
}

.mat-typography quill-editor .ql-snow .ql-editor h1 {
  font-size: 1.5rem;
}
.mat-typography quill-editor .ql-snow .ql-editor h2 {
  font-size: 1.125rem;
}

.mat-typography quill-editor .ql-snow .ql-editor h5 {
  font-size: 0.75rem;
}
