color-sketch {
  .sketch-sliders {
    display: flex;
    align-items: center;
    padding-bottom: 0 !important;

    .sketch-double {
      padding-right: 10px;
    }

    .sketch-hue {
      flex: 1;
    }
  }

  .sketch-alpha {
    display: none;
  }

  input {
    height: initial !important;
    margin: 0 !important;
    text-align: center;
  }
}
