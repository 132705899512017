@use '@angular/material' as mat;

/***********************************************
 * Primary Color
 ***********************************************/

$blue: #3b4c5b; // rgba(59, 76, 91, 1.0)

// from http://www.color-hex.com/color/3b4c5b
// 1 means mixed with 10% of white or black

$blue-lighten-1: #4e5d6b;
$blue-lighten-2: #626f7b;
$blue-lighten-3: #75818c;
$blue-lighten-4: #89939c;
$blue-lighten-5: #9da5ad;
$blue-lighten-6: #b0b7bd;
$blue-lighten-7: #c4c9cd;
$blue-lighten-8: #d7dbde;
$blue-lighten-9: #ebedee;
$blue-lighten-10: #f5f6f6;

$blue-darken-1: #354451;
$blue-darken-2: #2f3c48;
$blue-darken-3: #29353f;
$blue-darken-4: #232d36;
$blue-darken-5: #1d262d;
$blue-darken-6: #171e24;
$blue-darken-7: #11161b;
$blue-darken-8: #0b0f12;
$blue-darken-9: #050709;

$mat-blue: (
  50: #e7eaeb,
  100: #c4c9ce,
  200: #9da6ad,
  300: #76828c,
  400: #586774,
  500: #3b4c5b,
  600: #354553,
  700: #2d3c49,
  800: #263340,
  900: #19242f,
  A100: #73b5ff,
  A200: #409aff,
  A400: #0d80ff,
  A700: #0073f2,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

/***********************************************
 * Accent Color
 ***********************************************/

$orange: #f8931e; // rgba(248, 147, 30, 1.0)

// from http://www.color-hex.com/color/f8931e
// 1 means mixed with 10% of white or black

$orange-lighten-1: #f89d34;
$orange-lighten-2: #f9a84a;
$orange-lighten-3: #fab361;
$orange-lighten-4: #fabe78;
$orange-lighten-5: #fbc98e;
$orange-lighten-6: #fcd3a5;
$orange-lighten-7: #fcdebb;
$orange-lighten-8: #fde9d2;
$orange-lighten-9: #fef4e8;

$orange-darken-1: #df841b;
$orange-darken-2: #c67518;
$orange-darken-3: #ad6615;
$orange-darken-4: #945812;
$orange-darken-5: #7c490f;
$orange-darken-6: #633a0c;
$orange-darken-7: #4a2c09;
$orange-darken-8: #311d06;
$orange-darken-9: #180e03;

$mat-orange: (
  50: #fef2e4,
  100: #fddfbc,
  200: #fcc98f,
  300: #fab362,
  400: #f9a340,
  500: #f8931e,
  600: #f78b1a,
  700: #f68016,
  800: #f57612,
  900: #f3640a,
  A100: #ffffff,
  A200: #fff0e9,
  A400: #ffcfb6,
  A700: #ffbe9c,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

// http://mcg.mbitson.com/#!?dtorange=%23f8931e

/***********************************************
 * Other Colors
 ***********************************************/

// The gray color that is used as background for inputboxes/impact-matrix etc.
$inputbox-background: rgba(59, 76, 91, 0.1);
$inputbox-border: rgba(59, 76, 91, 0.2);
$inputbox-foreground: rgba(0, 0, 0, 0.87);

// Modal color
$modal-background: #fafafa;

// Different foreground colors
$foreground: rgba(0, 0, 0, 0.87);
$grey-foreground: rgba(0, 0, 0, 0.6);

$polar-chart-background: rgba(59, 76, 91, 0.1);
$polar-chart-grid-color: rgba(59, 76, 91, 0.25);

$crimson-red: #dc143c;

$help-blue: #469ab9;
$help-blue-lighten-1: #58a4c0;
$help-blue-lighten-2: #6aaec7;
$help-blue-lighten-3: #7db8ce;
$help-blue-lighten-4: #90c2d5;
$help-blue-lighten-5: #a2ccdc;
$help-blue-lighten-6: #b5d6e3;
$help-blue-lighten-7: #c7e0ea;
$help-blue-lighten-8: #daeaf1;
$help-blue-lighten-9: #ecf4f8;

$objective: #5d666f;

$objective-lighten-1: #6d757d;
$objective-lighten-2: #7d848b;
$objective-lighten-3: #8d939a;

$objective-darken-1: #535b63;
$objective-darken-2: #4a5158;
$objective-darken-3: #41474d;

$alternative: $blue-lighten-9;

/***********************************************
 * Spacing
 ***********************************************/

$bottom-bar-height: 47px;
$starter-padding: 24px;

/***********************************************
 * Typography Config
 ***********************************************/

// See https://v15.material.angular.io/guide/typography for more information.
$typography: mat.define-typography-config(
  $headline-4: mat.define-typography-level(16px, 1.5, 500),
  $headline-5: mat.define-typography-level(24px, 32px, 400),
  $headline-6: mat.define-typography-level(20px, 28px, 500),
  $subtitle-1: mat.define-typography-level(20px, 1.5, 400),
  $subtitle-2: mat.define-typography-level(18px, 1.5, 400),
  $body-1: mat.define-typography-level(15px, 1.6, 400),
  $body-2: mat.define-typography-level(15px, 1.5, 400),
  $caption: mat.define-typography-level(13px, 1.5, 400),
  $button: mat.define-typography-level(14px, 1.5, 500),
);

@mixin typography-level($level) {
  @include mat.typography-level($typography, $level);
}

@function font-size($level) {
  @return mat.font-size($typography, $level);
}

@mixin info-box {
  display: flex;
  align-items: center;

  border-radius: 5px;
  padding: 8px;
  background-color: #ddd;

  mat-icon {
    margin-right: 0.3ch;
  }
}

/***********************************************
 * Other Constants
 ***********************************************/
$modal-padding: 1.5rem; // The default padding used for the modal header + content, can be used to align other things with the modal header.
