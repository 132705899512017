@use 'variables' as dt;
@use 'sass:color';

.mdc-checkbox {
  --mdc-checkbox-selected-checkmark-color: #fff;
}

.mat-mdc-optgroup-label {
  color: #0000008a;
}

.mat-sort-header-button:focus {
  background: none;
}

mat-icon.dt-smaller-icon {
  font-size: 20px;
  width: 20px;
  height: 20px;
}

.mat-mdc-form-field {
  .mat-mdc-floating-label,
  .mat-mdc-form-field-hint {
    color: #0009;
  }

  .mat-mdc-floating-label {
    width: calc(100% / var(--mat-mdc-form-field-floating-label-scale));
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .mat-mdc-form-field-hint-wrapper,
  .mat-mdc-form-field-error-wrapper {
    padding: 0 !important;

    .mat-mdc-form-field-hint {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .mat-mdc-form-field-text-suffix {
    white-space: nowrap;
  }
}

// Otherwise the default background color will shine through at rounded corners.
.mat-mdc-dialog-container .mdc-dialog__surface {
  --mdc-dialog-container-color: transparent;
}

.mat-mdc-radio-button:not([ng-reflect-disabled]) {
  label {
    cursor: pointer;
  }
}

// Only show the first error in a form field
mat-form-field mat-error:not(:first-of-type) {
  display: none;
}

.mat-mdc-subheader {
  color: #0000008a;
  font-size: dt.font-size(body-2);
  font-weight: 500;
}

.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: inherit !important;
}

.mat-mdc-header-cell {
  // Since v15, the standard typography level for header cells is subheading-1 (which is too big for us).
  @include dt.typography-level(caption);
  color: #0000008a;
  font-weight: 500;
}

.mat-mdc-radio-button:not([ng-reflect-disabled]) {
  label {
    cursor: pointer;
  }
}

.mat-row.mat-mdc-no-data-row .mat-cell {
  padding: 0 16px;
  height: 52px;
}

.mat-typography h4 {
  @include dt.typography-level(subtitle-2);
}

button {
  &.mdc-icon-button {
    // Fix for ress breaking centering in material icon buttons
    font: unset;
  }

  &.mat-mdc-button-base .mdc-button__label {
    max-width: 100%;
  }

  &.mat-accent-less:not(:disabled) {
    &.mat-mdc-raised-button,
    &.mat-mdc-unelevated-button {
      border: 1px solid dt.$orange;
      color: dt.$orange;
      background-color: white;
    }
  }

  &.mat-accent:not(:disabled) {
    &.mat-mdc-raised-button,
    &.mat-mdc-unelevated-button,
    &.mdc-fab {
      // Required until angular material fixes its handling of contrast colors
      // https://github.com/angular/components/issues/26056
      color: white;
    }
  }

  &.mat-help:not(:disabled) {
    &.mat-mdc-raised-button,
    &.mat-mdc-unelevated-button {
      color: white;
      --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
      --mat-mdc-button-persistent-ripple-color: #fff;
      background-color: darken(dt.$help-blue, 20%);
    }
  }
}

.dt-mat-dialog-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}

body {
  --mdc-list-list-item-selected-container-color: #e1dfe4;
}
