@use 'variables' as dt;

@mixin dt-error {
  display: flex;
  align-items: center;

  background-color: dt.$orange-lighten-8;
  color: dt.$blue-darken-5;
  border-radius: 5px;
  padding: 10px;

  &:before {
    font-family: 'Material Symbols Outlined';
    // Code point for the error icon. Safari does not like the text selector.
    content: '\e000';
    color: #ff9800;
    margin-right: 0.3ch;
    font-size: 24px;
    height: auto;
    line-height: 1;
  }
}

form.dt-form {
  display: flex;
  flex-direction: column;

  mat-form-field {
    mat-error + mat-error {
      display: none !important;
    }

    .mat-form-field-subscript-wrapper {
      margin-top: 0.366667em !important;
    }
  }

  button[type='submit'] {
    align-self: center;
    margin-top: 0.5rem;
  }

  mat-checkbox[required] {
    font-weight: 500;
  }

  > mat-error {
    @include dt-error;
  }
}

mat-error.dt-error {
  @include dt-error;
}

// Disable up down arrows in mat-form-field with input type "number"
mat-form-field {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}

fieldset {
  all: unset;
  width: 100%;
}
