/*------------------------------*\
  #INPUTBOX
\*------------------------------*/
@use 'variables' as dt;

/**
 * .inputbox-Containers contain form elements like text inputs or value controls
 */
.dt-inputbox {
  display: flex;
  color: rgba(0, 0, 0, 0.5);
  background-color: #ebedee; // rgba(dt.$blue, 0.1)
  border: 1px solid #d7dbde; // rgba(dt.$blue, 0.2)
  border-radius: 2px;
  overflow: visible;
  padding: 5px 10px;
  margin: 10px 0;

  /**
   * makes the .inputbox-Container invisible
   */
  &.invisible {
    background-color: rgba(dt.$blue, 0);
    border: 1px solid rgba(dt.$blue, 0);
  }

  .ziel {
  }

  /// ziele, alternativen, einstellungen
  table {
    td {
      padding: 0;

      &.caption {
        text-align: right;
        padding-right: 10px;
        width: 10px;
        white-space: nowrap;
        vertical-align: baseline;
      }

      &:not(.caption) {
        width: auto;
        vertical-align: baseline;
      }

      &.input-field.auspraegungen {
        display: flex;
      }

      .radio-field {
        min-height: 48.5px;

        label {
          color: rgba(0, 0, 0, 1);
        }
      }

      .radio-num,
      .radio-ver {
        display: flex;
        align-items: baseline;

        div input {
          width: 100px;
          margin-left: 10px;
        }
      }

      .radio-num div,
      .radio-ver div {
        display: flex;
        align-items: baseline;
        margin: 0;
        padding-left: 20px;
      }
    }
  }

  &.unsicherheitsfaktor {
    &:hover {
      border: 1px solid rgb(8, 24, 38);
    }
  }

  .line {
    .caption {
      margin-right: 10px;
      text-align: right;
    }

    .text {
      color: initial;
    }
  }

  .input-field {
    margin: 0;
    position: initial;
  }

  // group: place for content
  // control: place for button etc
  .group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;

    .newline {
      display: flex;
      align-items: baseline;
      width: 100%;

      input[type='text'] {
        min-width: 150px;
      }

      input[type='number'] {
        min-width: 50px;
        width: 50px;
      }

      .caption {
        margin-right: 10px;
        text-align: right;
      }

      .input-field {
        &.flex {
          flex: 1;
        }

        &.uf-probability-input {
          margin-left: 10px;
        }

        &.flex.bar-slider {
          align-self: center;
          position: relative !important;
          height: 40px;
          min-width: 125px;
        }
      }
    }
  }

  input[type='text'],
  input[type='number'],
  input[type='email'],
  input[type='password'] {
    // height: 2.5rem;
    margin: 0 0 10px 0;
  }

  &.small {
    padding: 2px 10px;
    margin: 0;
    border: none;
    border-radius: 0;
    background-color: rgba(59, 76, 91, 0);

    .group .newline .input-field.flex.bar-slider {
      height: 21px;
    }

    input[type='text'] {
      height: 20px;
      margin: 0;
    }

    button {
      height: 21px;
      line-height: 21px;
    }
  }
}

// experimental

.inputbox2 {
  display: flex;
  flex-flow: column;
  color: rgba(0, 0, 0, 0.5);
  background-color: #ebedee; // rgba(dt.$blue, 0.1)
  border: 1px solid dt.$blue; // rgba(dt.$blue, 0.2)
  border-radius: 2px;
  overflow: visible;
  /*padding: 5px 10px;*/
  margin: 10px 0;

  /**
   * makes the .inputbox-Container invisible
   */
  &.invisible {
    background-color: rgba(dt.$blue, 0);
    border: 1px solid rgba(dt.$blue, 0);
  }

  .ziel {
  }

  /// ziele, alternativen, einstellungen
  table {
    td {
      padding: 0;

      &.caption {
        text-align: right;
        padding-right: 10px;
        width: 10px;
        white-space: nowrap;
        vertical-align: baseline;
      }

      &:not(.caption) {
        width: auto;
        vertical-align: baseline;
      }

      &.input-field.auspraegungen {
        display: flex;
      }

      .radio-field {
        min-height: 48.5px;

        label {
          color: rgba(0, 0, 0, 1);
        }
      }

      .radio-num,
      .radio-ver {
        display: flex;
        align-items: baseline;

        div input {
          width: 100px;
          margin-left: 10px;
        }
      }

      .radio-num div {
        display: flex;
        align-items: baseline;
        margin: 0;
        padding-left: 20px;
      }
    }
  }

  &.unsicherheitsfaktor {
    &:hover {
      border: 1px solid rgb(8, 24, 38);
    }
  }

  .line {
    .caption {
      margin-right: 10px;
      text-align: right;
    }

    .text {
      color: initial;
    }
  }

  .input-field {
    margin: 0;
    position: initial;
  }

  &.note-wrapper {
    .note-btn-wrapper .poly:not(.not-empty) {
      fill: #ffffff;
    }

    .header .control {
      margin-right: 20px;
    }
  }

  .header {
    display: flex;
    align-items: stretch;
    background-color: dt.$blue;
    color: #ffffff;
    padding-left: 5px;
    border-bottom: 1px solid dt.$blue;

    .caption {
      font-weight: bold;
      flex-grow: 1;
      display: flex;
      align-items: center;
    }

    .control {
      button {
        background-color: rgba(dt.$blue, 0);
        height: 25px;
        line-height: 25px;
        padding: 0;
        box-shadow: none;
      }
    }
  }

  .content {
    padding: 5px 0;
  }

  // group: place for content
  // control: place for button etc
  .group {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    width: 100%;
    padding: 5px;

    .content {
      flex-grow: 1;
    }

    .control {
    }

    .newline {
      display: flex;
      align-items: baseline;
      width: 100%;

      input[type='text'] {
        min-width: 150px;
      }

      input[type='number'] {
        min-width: 50px;
        width: 50px;
      }

      .caption {
        margin-right: 10px;
        text-align: right;
      }

      .input-field {
        &.flex {
          flex: 1;
        }

        &.uf-probability-input {
          margin-left: 10px;
        }

        &.flex.bar-slider {
          align-self: center;
          position: relative !important;
          height: 40px;
          min-width: 125px;
        }
      }
    }
  }

  input[type='text'],
  input[type='number'],
  input[type='email'],
  input[type='password'] {
    // height: 2.5rem;
    margin: 0 0 10px 0;
  }

  &.small {
    padding: 2px 10px;
    margin: 0;
    border: none;
    border-radius: 0;
    background-color: rgba(59, 76, 91, 0);

    .group .newline .input-field.flex.bar-slider {
      height: 21px;
    }

    input[type='text'] {
      height: 20px;
      margin: 0;
    }

    button {
      height: 21px;
      line-height: 21px;
    }
  }
}

// for the new inputbox component

dt-inputbox {
  .control-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .content-wrapper {
    width: 100%;

    .line {
      display: flex;
      align-items: center;

      .caption-field {
        width: 350px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .number-field {
        padding: 0 5px;
        width: 45px;
        text-align: center;
      }

      .control-field {
        flex: 1 1 auto;
      }
    }
  }
}

/**
 * Border and colors for a simple inputbox (like used in objective weighting)
 */
%inputbox-appearance {
  background-color: dt.$inputbox-background;
  color: dt.$inputbox-foreground;
  border: 1px solid dt.$inputbox-border;
  border-radius: 2px;
}
