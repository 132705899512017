@use 'variables' as dt;

/*********/
/* forms *
/*********/

input {
  hyphens: none;
}

input:not(.browser-default, [matInput]) {
  color: rgba(0, 0, 0, 1);
  box-shadow: none;

  &[type='text'],
  &[type='password'],
  &[type='number'],
  &[type='email'] {
    margin: 5px 0;
    height: 2rem;
    width: 100%;
    border-bottom: 1px solid #9e9e9e;
  }

  &[type='text'].ng-touched.ng-invalid:not(:focus),
  &[type='password'].ng-touched.ng-invalid:not(:focus),
  &[type='number'].ng-touched.ng-invalid:not(:focus),
  &[type='text'].invalid:not(:focus),
  &[type='password'].invalid:not(:focus),
  &[type='number'].invalid:not(:focus) {
    border-bottom: 2px solid #ff0000;
  }

  &[type='text']:focus,
  &[type='password']:focus,
  &[type='number']:focus {
    outline: none;
    border-bottom: 2px solid dt.$orange;
  }

  &[type='radio']:checked + label::after {
    background-color: dt.$orange;
    border: 2px solid dt.$orange;
  }

  &[type='number'] {
    text-align: left;
  }

  &[type='number'].mr10,
  &[type='text'].mr10 {
    margin-right: 10px;
  }

  &[type='number'].ml10,
  &[type='text'].ml10 {
    margin-left: 10px;
  }

  /* slider */

  &[type='range'] {
    border: none;
  }

  &[type='range'] {
    -webkit-appearance: none;
    width: 100%;
    margin: 0.95px 0;
  }

  &[type='range']:focus {
    outline: none;
  }

  &[type='range']::-webkit-slider-runnable-track {
    width: 100%;
    height: 8.1px;
    cursor: pointer;
    box-shadow:
      1.1px 1.1px 1px rgba(0, 0, 0, 0),
      0 0 1.1px rgba(13, 13, 13, 0);
    background: rgba(48, 113, 169, 0.14);
    border-radius: 25px;
    border: 0 solid rgba(0, 1, 1, 0.99);
  }

  &[type='range']::-moz-range-track {
    width: 100%;
    height: 8.1px;
    cursor: pointer;
    box-shadow:
      1.1px 1.1px 1px rgba(0, 0, 0, 0),
      0 0 1.1px rgba(13, 13, 13, 0);
    background: rgba(48, 113, 169, 0.14);
    border-radius: 25px;
    border: 0 solid rgba(0, 1, 1, 0.99);
  }

  &[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    box-shadow:
      2.9px 2.9px 13.7px rgba(0, 0, 0, 0.3),
      0 0 2.9px rgba(13, 13, 13, 0.3);
    border-radius: 50px;
    cursor: pointer;
    margin-top: -6.5px;
    border: 6.5px solid rgba(248, 147, 30, 1);
    background: rgba(255, 255, 255, 1);
    height: 23px;
    width: 23px;
  }

  &[type='range']::-moz-range-thumb {
    box-shadow:
      2.9px 2.9px 13.7px rgba(0, 0, 0, 0.3),
      0 0 2.9px rgba(13, 13, 13, 0.3);
    border: 6.5px solid rgba(248, 147, 30, 1);
    height: 10px;
    width: 10px;
    border-radius: 50px;
    background: rgba(255, 255, 255, 1);
    cursor: pointer;
  }

  &[type='range']::-ms-thumb {
    box-shadow:
      2.9px 2.9px 13.7px rgba(0, 0, 0, 0.3),
      0 0 2.9px rgba(13, 13, 13, 0.3);
    border: 6.5px solid rgba(248, 147, 30, 1);
    height: 8.1px;
    width: 10px;
    border-radius: 50px;
    background: rgba(255, 255, 255, 1);
    cursor: pointer;
  }

  &[type='range']::-ms-track {
    width: 100%;
    height: 8.1px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  &[type='range']::-ms-fill-lower {
    background: rgba(45, 105, 157, 0.14);
    border: 0 solid rgba(0, 1, 1, 0.99);
    border-radius: 50px;
    box-shadow:
      1.1px 1.1px 1px rgba(0, 0, 0, 0),
      0 0 1.1px rgba(13, 13, 13, 0);
  }

  &[type='range']::-ms-fill-upper {
    background: rgba(48, 113, 169, 0.14);
    border: 0 solid rgba(0, 1, 1, 0.99);
    border-radius: 50px;
    box-shadow:
      1.1px 1.1px 1px rgba(0, 0, 0, 0),
      0 0 1.1px rgba(13, 13, 13, 0);
  }
}
