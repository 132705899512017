.cdk-overlay-container {
  z-index: 1000;
}

.mat-mdc-snack-bar-container.dt-snackbar-container {
  z-index: 1001;

  margin-top: 36px + 8px;
  width: unset !important;
}
