@use 'variables' as dt;

#preload-page {
  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  inset: 0;
  background-color: white;
  z-index: 10000000;

  dt-app:empty + & {
    opacity: 1;
    visibility: visible;
  }

  &.transition {
    transition:
      opacity 250ms ease-out,
      visibility 0ms 250ms linear;
  }

  opacity: 0;
  visibility: hidden;

  img {
    width: min(100px, 50vw);
    position: absolute;
  }

  .preload-spinner {
    display: flex;
    align-items: center;
    justify-content: center;

    width: min(100px, 50vw);
    height: min(100px, 50vw);
    position: absolute;

    div {
      width: 100%;
      height: 100%;

      position: absolute;
      border: 2px solid dt.$blue;
      opacity: 1;
      border-radius: 50%;
      animation: preload-spinner 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;

      &:nth-child(2) {
        animation-delay: -0.5s;
      }
    }

    @keyframes preload-spinner {
      0% {
        transform: scale(1);
        opacity: 1;
      }
      100% {
        transform: scale(2);
        opacity: 0;
      }
    }
  }
}
