.cdk-overlay-pane.dt-fullscreen-panel {
  background-color: rgba(0, 0, 0, 0.5);
  flex-direction: column;
  align-items: center;
  justify-content: center;

  pointer-events: none;

  > * {
    pointer-events: auto;
  }
}
