@use '@angular/material' as mat;
@use 'variables' as dt;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
@include mat.typography-hierarchy(dt.$typography);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$dt-primary: mat.define-palette(dt.$mat-blue);
$dt-accent: mat.define-palette(dt.$mat-orange);

// The warn palette is optional (defaults to red).
$dt-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$dt-theme: mat.define-light-theme(
  (
    color: (
      primary: $dt-primary,
      accent: $dt-accent,
      warn: $dt-warn,
    ),
    typography: dt.$typography,
  )
);
$dt-theme-dark: mat.define-dark-theme(
  (
    color: (
      primary: $dt-primary,
      accent: $dt-accent,
      warn: $dt-warn,
    ),
    typography: dt.$typography,
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($dt-theme);
@include mat.icon-button-density(-2);

.dt-dark-theme {
  color: white;

  @include mat.all-component-colors($dt-theme-dark);
}
